import * as React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import '@ant-design/v5-patch-for-react-19'
import { RouteNames } from 'route/routes-map'

import * as userv1 from '../proto/iam/v1/user_pb'

import * as authSelectors from '../store/auth/reducer'
import * as branchSelectors from '../store/iam/branch/reducer'
import * as organizationSelectors from '../store/iam/organization/reducer'
import * as userSelectors from '../store/iam/user/reducer'
import * as userGroupSelectors from '../store/iam/usergroup/reducer'
import { RootState } from '../store/reducer'

import { FullSizeSpinner } from '../components/Spinners'

import '../variables.css'

interface AuthProps {
  children: JSX.Element
  redirectTo: string
}
const AuthenticatedApp = ({ children, redirectTo }: AuthProps): JSX.Element => {
  // Auth
  const isAuthenticated = useSelector<RootState>((state) => authSelectors.getIsAuthenticated(state))
  const authFetching = useSelector<RootState>((state) => authSelectors.getIsFetching(state))
  const authErr = useSelector<RootState, Error | undefined>((state) => authSelectors.getErr(state))

  // User
  const currentUser = useSelector<RootState, userv1.User | undefined>((state) =>
    userSelectors.getCurrentUser(state),
  )
  const userFetching = useSelector<RootState, boolean>((state) =>
    userSelectors.getIsFetching(state),
  )
  const userErr = useSelector<RootState, Error | undefined>((state) => userSelectors.getErr(state))

  // Branch
  const branchErr = useSelector<RootState, Error | undefined>((state) =>
    branchSelectors.getErr(state),
  )
  const branchIsFetching = useSelector<RootState, boolean>((state) =>
    branchSelectors.getIsFetching(state),
  )

  // Org
  const isFetchingOrganizations = useSelector<RootState, boolean>((state) =>
    organizationSelectors.getIsFetching(state),
  )
  const organizationsErr = useSelector<RootState, Error | undefined>((state) =>
    organizationSelectors.getErr(state),
  )

  // Group
  const userGroupFetching = useSelector<RootState, boolean>((state) =>
    userGroupSelectors.getIsFetching(state),
  )
  const groupErr = useSelector<RootState, Error | undefined>((state) =>
    userGroupSelectors.getErr(state),
  )

  if (authErr) {
    return <Navigate to={RouteNames.Login} />
  }

  if (!currentUser) {
    if (userErr) {
      throw new Error('could not get current user: ' + userErr.message)
    }
    if (organizationsErr) {
      throw new Error('could not get organizations: ' + organizationsErr.message)
    }
    if (groupErr) {
      throw new Error('could not get groups: ' + groupErr.message)
    }
    if (branchErr) {
      throw new Error('could not get branches: ' + branchErr.message)
    }
  }

  if (
    authFetching ||
    userFetching ||
    isFetchingOrganizations ||
    userGroupFetching ||
    branchIsFetching
  ) {
    return <FullSizeSpinner />
  }

  return isAuthenticated ? children : <Navigate to={redirectTo} />
}

export default AuthenticatedApp
